import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";

const AccordionOne = ({ customStyle }) => {
  return (
    <Accordion
      className={`rn-accordion-style ${customStyle}`}
      defaultActiveKey="0"
    >
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            Posso confiar nos produtos e serviços oferecidos pelos parceiros da
            ETS CORP?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            Sim, todos os parceiros da ETS CORP passam por um rigoroso processo
            de seleção para garantir que ofereçam produtos e serviços de alta
            qualidade. Estamos comprometidos em fornecer aos nossos clientes as
            melhores soluções disponíveis no mercado.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="1">
            Existe algum benefício em escolher os parceiros da ETS CORP em vez
            de outras opções?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            Sim, ao escolher os parceiros da ETS CORP, você se beneficia da
            nossa expertise e experiência em selecionar os melhores parceiros do
            mercado. Isso garante que você receba produtos e serviços de alta
            qualidade, suporte excepcional e uma experiência satisfatória do
            cliente.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="2">
            Como posso me beneficiar ao me tornar um microfranqueado da ETS
            CORP?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            Como microfranqueado da ETS CORP, você terá acesso a uma plataforma
            de vendas exclusiva, suporte contínuo da empresa, oportunidades de
            treinamento e desenvolvimento, além de uma comissão atraente sobre
            as vendas realizadas.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="3">
            Como posso me tornar um parceiro da ETS CORP?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            Para se tornar um parceiro da ETS CORP, você pode entrar em contato
            conosco através do formulário de contato em nosso site ou enviar um
            e-mail diretamente para nossa equipe de parcerias. Teremos o prazer
            de discutir as oportunidades de colaboração disponíveis e os
            requisitos necessários para iniciar uma parceria bem-sucedida.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
export default AccordionOne;
