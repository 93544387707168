import React from "react";
import BlogClassicData from "../data/blog/BlogList.json";
import TermosDetails from "../components/blog/TermosDetails";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import PoliticaPrivacidadeDetails from "../components/blog/PoliticaPrivacidadeDetails";

const PoliticaPrivacidade = ({
  match: {
    params: { id },
  },
}) => {
  const blogId = parseInt(id, 10);
  const data = BlogClassicData.filter((blog) => blog.id === blogId);

  return (
    <>
      <SEO title="Politica de Privacidade" />
      <Layout>
        <div className="rn-blog-details-area">
          <PoliticaPrivacidadeDetails />
        </div>
      </Layout>
    </>
  );
};
export default PoliticaPrivacidade;
