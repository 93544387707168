import React from "react";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import BrandThree from "../elements/brand/BrandThree";
import AboutFour from "../elements/about/AboutFour";
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const Data = [
  {
    countNum: 400,
    type: "plus",
    countTitle: "Usuários",
  },
  {
    countNum: 100,
    countTitle: "Satisfação de produtos",
    type: "percentage",
  },
  {
    countNum: 100,
    countTitle: "Cadastro de usuários hoje",
    type: "percentage",
  },
];

const AboutUs = () => {
  return (
    <>
      <SEO title="Sobre" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          // HeaderSTyle="header-transparent"
        />

        {/* Start Slider Area  */}
        <div
          className="slider-area slider-style-1 height-850 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/etsparah/banner/sobre.png)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner pt--80 text-center">
                  <div>
                    <h3 className="rn-sub-badge">
                      <span className="theme-gradient">Conheça a</span>
                    </h3>
                  </div>
                  <h1 className="title display-one">
                    ETS CORP <br />
                    BUSINESS CENTER
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Service Area  */}
        <div className="service-area rn-section-gapTop">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="content">
                  <h3 className="title">
                    Um Hub de Oportunidades em suas mãos.
                  </h3>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb--10">
                  Estamos comprometidos em oferecer serviços de alta qualidade,
                  orientação especializada e oportunidades de crescimento que
                  promovam a prosperidade e o sucesso de nossos
                  microfranqueados.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Brand Area  */}
        <div className="rwt-brand-area mt-3">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle=""
                  title=""
                  description=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt--10">
                <BrandThree brandStyle="brand-style-2 variation-2" />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area  */}

        {/* Start Brand Area  */}
        {/* <div className="pb--60 pt--30">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mt--10">
                <CalltoActionFiveAbout />
              </div>
            </div>
          </div>
        </div> */}
        {/* End Brand Area  */}

        <Separator />

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Conheça agora"
                  title="Nosso Business Center"
                  description="Proporcionando oportunidades únicas aos nossos microfranqueados <br/> através da integração com parcerias estratégicas."
                />
              </div>
            </div>
            <ServiceOne
              serviceStyle="service__style--1 bg-color-blackest radius mt--25"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}

        <div className="rwt-timeline-area rn-section-gapBottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <TimelineTwo classVar="no-gradient" />
              </div>
            </div>
          </div>
        </div>

        <Separator />

        <AboutFour image="./images/etsparah/banner/613x558.png" />

        <div className="mb-5 d-flex flex-column align-items-center flex-lg-row col-lg-12 col-xl-12 col-12 justify-content-center">
          {Data.map((data, index) => (
            <div
              className="col-lg-4 col-xl-3 col-md-4 col-sm-6 col-12"
              key={index}
            >
              <div className="count-box counter-style-4 text-center">
                <TrackVisibility once>
                  <CountUp
                    className="count-number2"
                    end={data.countNum}
                    suffix={
                      data.type === "plus"
                        ? " +"
                        : data.type === "mil"
                          ? " k"
                          : data.type === "percentage"
                            ? " %"
                            : ""
                    }
                  />
                </TrackVisibility>
                <h5 className="counter-title">{data.countTitle}</h5>
              </div>
            </div>
          ))}
        </div>

        <Separator />

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default AboutUs;
