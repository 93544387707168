import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactOne from "./ContactOne";

const Contato = () => {
  return (
    <>
      <SEO title="Contato" />
      <Layout>
        <BreadcrumbOne
          title="Entre em Contato com a ETSCORP: <br/>Estamos aqui para ajudar!"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Contato"
        />
        <div className="main-content">
          {/* Start Contact Area  */}
          <div className="rwt-contact-area rn-section-gap">
            <div className="container">
              <ContactOne />
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
      </Layout>
    </>
  );
};
export default Contato;
