import React from "react";
import SEO from "../common/SEO";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import ServiceTwo from "../elements/service/ServiceTwo";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import AboutTwo from "../elements/about/AboutTwo";
import CounterUpTwo from "../elements/counterup/CounterUpTwo";

import Separator from "../elements/separator/Separator";
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import PricingTwo from "../elements/pricing/PricingTwo";

const Home = () => {
  return (
    <>
      <SEO title="Home" />
      <main className="page-wrapper">
        <div className="header-transparent-with-topbar">
          <HeaderOne
            btnStyle="btn-small btn-icon"
            HeaderSTyle="header-not-transparent"
          />
        </div>

        {/* Start Slider area  */}
        <div
          className="slider-area slider-style-2 height-950 bg_image"
          data-black-overlay="2"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/etsparah/banner/poder-de-conquistar.png)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-start">
                  <h4 className="subtitle">
                    <span className="theme-gradient">Bem-vindo à ETS CORP</span>
                  </h4>
                  <h1 className="title display-one">
                    Sua central de <br />
                    negócios está aqui!
                  </h1>
                  <ul className="list-icon list-icon-custom">
                    <li>
                      <span className="icon-custom">
                        <FiCheck />
                      </span>
                      Diversidade empresarial
                    </li>
                    <li>
                      <span className="icon-custom">
                        <FiCheck />
                      </span>
                      Inovação Contínua
                    </li>
                    <li>
                      <span className="icon-custom">
                        <FiCheck />
                      </span>
                      Oportunidades Únicas
                    </li>
                  </ul>
                  <div className="button-group mt--40 mt_sm--20">
                <a href="/faca-parte">
                  <button className="btn-default btn-icon">
                    Acessar Painel
                    <i className="icon">
                    <FiArrowRight />
                  </i>
                </button>
              </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider area  */}

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Um Hub de Oportunidades"
                  title="Central de Negócios Digitais"
                  description="Como um Business Center de referência, buscamos constantemente identificar oportunidades e desenvolver <br/>estratégias que impulsionam o empreendedorismo e promovam um ambiente de negócios próspero."
                />
              </div>
            </div>
            <ServiceTwo cardStyle="card-style-1" textAlign="text-start" />
          </div>
        </div>
        {/* End Service Area  */}

        <Separator />

        <AboutTwo />

        <Separator />
        {/* Start Elements Area  */}
        <div className="rwt-counterup-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Nossos Números"
                  title="Desvendando o Impacto<br/> da ETSCORP"
                  description=""
                />
              </div>
            </div>
            <CounterUpTwo
              column="col-lg-4 col-md-6 col-sm-6 col-12"
              counterStyle="counter-style-2"
              textALign="text-center"
            />
          </div>
        </div>
        {/* End Elements Area  */}

        {/* <Separator />

        <div className="rwt-pricing-area rn-section-gap">
          <div className="container">
            <div className="row mb--40 mb_sm--0">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Planos"
                  title="Conheça nossos planos"
                  description=""
                />
              </div>
            </div>
            <PricingTwo />
          </div>
        </div>

        <Separator /> */}
        {/* Start Elements Area  */}
        {/* <div className="rwt-team-area rn-section-gap">
          <div className="wrapper plr--65 plr_md--15 plr_sm--15">
            <div className="row mb--20">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="ETSCORP"
                  title="Executivos da ETSCORP"
                  description=""
                />
              </div>
            </div>
            <TeamFour
              column="col-lg-6 col-xl-3 col-md-6 col-12 mt--30"
              teamStyle="team-style-three"
            />
          </div>
        </div> */}
        {/* End Elements Area  */}

        <Separator />

        {/* Start Call To Action Area  */}
        <div className="rwt-callto-action-area rn-section-gapBottom">
          <div className="wrapper">
            <CalltoActionFive />
          </div>
        </div>
        {/* End Call To Action Area  */}

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default Home;
