import React, { Component } from "react";
import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ text }) => <div>{text}</div>;
class GoogleMapStyle extends Component {
  static defaultProps = {
    center: {
      lat: 53.2734,
      lng: -7.77832031,
    },
    zoom: 20,
  };

  render() {
    return (
      <div style={{ width: "100%" }}>
        <iframe
          title="Google Maps"
          width="100%"
          height="600"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=pt-br&amp;q=AV.%20Affonso%20Penna,%20515%20-%20CONJ%2073,%20Estuario,%20Santos%20-%20SP.%20CEP:%2011.020-003+(ETS%20CORP)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        ></iframe>
      </div>
    );
  }
}

export default GoogleMapStyle;
