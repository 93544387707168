import React from "react";
import SEO from "../../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import Layout from "../../common/Layout";
import SectionTitle from "../sectionTitle/SectionTitle";
import EcossistemaEmpresas from "./EcossistemaEmpresas";
import AccordionOne from "../accordion/AccordionOne";

const Ecossistema = () => {
  return (
    <>
      <SEO title="Ecossistema || Seu Futuro Financeiro Começa Aqui!" />
      <Layout>
        <div
          className="slider-area slider-style-1 variation-default height-850 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/etsparah/banner/ecossistema2.png)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <h1 className="title display-one">
                    Parcerias Digitais para o Sucesso Empresarial
                  </h1>
                  <p className="description">
                    Trabalhamos em estreita colaboração com empresas do mercado
                    de empreendedorismo digital.
                  </p>
                  <div className="button-group">
                    <a
                      className="btn-default btn-medium round btn-icon"
                      target="_blank"
                      href="https://wa.me/5511932360714"
                      rel="noreferrer"
                    >
                      Falar com Suporte
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </a>
                    <Link
                      className="btn-default remove-buttton btn-medium btn-border round btn-icon"
                      to="contato"
                    >
                      Entre em Contato
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="main-content">
          <div className="rwt-portfolio-area rn-section-gap pb-0">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Nosso Ecossistema "
                    title="Parcerias Estratégicas <br/>E Inovação Empresarial"
                    description=""
                  />
                </div>
              </div>
              <EcossistemaEmpresas Column="col-lg-4 col-md-6 mt--30 portfolio" />

              <div className="container py-5"></div>
            </div>

            <div className="rn-accordion-area">
              <div className="container">
                <div className="row">
                  <div className="col-lg-10 offset-lg-1">
                    <SectionTitle
                      textAlign="text-center"
                      radiusRounded=""
                      subtitle="Tire suas duvidas"
                      title="Perguntas Frequentes"
                      description=""
                    />
                  </div>
                </div>
                <div className="row mt--35 row--20">
                  <div className="col-lg-10 offset-lg-1">
                    <AccordionOne customStyle="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default Ecossistema;
