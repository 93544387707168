import React from "react";
import EcossistemaDetailsContent from "../elements/portfolio/EcossistemaDetailsContent";
import EcossistemaData from "../data/portfolio/EcossistemaData.json";
import SEO from "../common/SEO";
import Layout from "../common/Layout";

const EcossistemaDetails = ({
  match: {
    params: { id },
  },
}) => {
  const portfolioId = parseInt(id, 10);
  const data = EcossistemaData.filter(
    (portfolio) => portfolio.id === portfolioId
  );
  return (
    <>
      <SEO title="Detalhes da Empresa || ETSCORP" />
      <Layout>
        <EcossistemaDetailsContent data={data[0]} />
      </Layout>
    </>
  );
};

export default EcossistemaDetails;
