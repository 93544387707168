import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";

const AboutTwo = () => {
  return (
    <div className="rwt-about-area about-style-2 rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-5">
            <div className="thumbnail">
              <img
                className="w-100"
                src="./images/etsparah/banner/526x622.png"
                alt="About Images"
              />
            </div>
          </div>

          <div className="col-lg-7 mt_md--30 mt_sm--30">
            <div className="content">
              <div className="section-title">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <h4 className="subtitle">
                    <span className="theme-gradient">Sobre nós</span>
                  </h4>
                  <h2 className="title mt--10">Conheça a ETS CORP</h2>
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <p>
                    Aqui, acreditamos na inovação e na busca incessante pela
                    excelência. <br />
                    Somos um Business Center, comprometidos em fornecer um
                    ambiente <br />
                    propício para o crescimento e o sucesso dos nossos
                    parceiros.
                  </p>

                  <ul className="list-icon">
                    <li>
                      <span className="icon">
                        <FiCheck />
                      </span>
                      Possibilidade de crescimento e expansão
                    </li>
                    <li>
                      <span className="icon">
                        <FiCheck />
                      </span>
                      Acesso a uma plataforma integrada
                    </li>
                    <li>
                      <span className="icon">
                        <FiCheck />
                      </span>
                      Impulsionamos o empreendedorismo
                    </li>
                    <li>
                      <span className="icon">
                        <FiCheck />
                      </span>
                      Produtos e serviços de alta qualidade
                    </li>
                  </ul>
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <div className="read-more-btn mt--40">
                    <Link className="btn-default btn-icon" to="/sobre-nos">
                      Saiba mais
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTwo;
