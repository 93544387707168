import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const TabOne = () => {
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs>
            <div className="row row--30 align-items-center">
              <div className="col-lg-5">
                <img
                  className="radius-small"
                  src="./images/etsparah/banner/1000x720.png"
                  alt="Corporate React Template"
                />
              </div>
              <div className="col-lg-7 mt_md--40 mt_sm--40">
                <div className="rn-default-tab">
                  <div className="tab-button-panel">
                    <TabList className="tab-button">
                      <Tab>
                        <div className="rn-tab-button">
                          <button>OPORTUNIDADE</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>USABILIDADE</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>CRESCIMENTO</button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className="tab-content-panel">
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            Nossos microfranqueados ganham uma oportunidade
                            única de iniciar um negócio próprio, com aplicações
                            iniciais acessíveis e flexíveis, que não estão
                            disponíveis em outras plataformas.
                          </p>

                          <p>
                            Além disso, a ETS CORP proporciona materiais de alta
                            qualidade e uma plataforma integrada exclusiva, que
                            possibilita facilidades desde o início.
                          </p>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            Nossa plataforma foi projetada com foco na
                            usabilidade, garantindo que até mesmo os usuários
                            menos experientes possam navegar e utilizar todas as
                            suas funcionalidades com facilidade.
                          </p>

                          <p>
                            Com recursos automatizados e integrados, você poderá
                            realizar suas atividades comerciais de forma mais
                            eficiente e produtiva, economizando tempo e
                            recursos.
                          </p>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            Ao ingressar em nossa rede de microfranqueados, você
                            terá acesso a uma comunidade de empreendedores
                            dedicados e experientes, prontos para oferecer
                            suporte e orientação.
                          </p>

                          <p>
                            Ser um microfranqueado da ETS CORP oferece a
                            oportunidade de desenvolver suas habilidades
                            empresariais e expandir sua carreira como
                            empreendedor.
                          </p>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabOne;
