import React from "react";
import { FiArrowRight } from "react-icons/fi";

const callToActionData = {
  title: "Ainda não sabe como transformar a sua vida?",
  subtitle: "",
  btnText: "EU QUERO!",
};

const CalltoActionTwo = () => {
  return (
    <div
      className="rn-callto-action clltoaction-style-default style-2 bg_image bg_image_fixed"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/etsparah/banner/transformando.png)`,
      }}
      data-black-overlay="8"
    >
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-10 offset-lg-1">
            <div className="inner">
              <div className="content text-center">
                <h2 className="title mb--0">{callToActionData.title}</h2>
                <p className="text-size18px">
                  Seja um microfranqueado da ETS CORP.
                </p>
                <div className="call-to-btn text-center">
                  <a className="btn-default btn-icon" href="https://wa.me/5511932360714">
                    {callToActionData.btnText}
                    <i className="icon">
                      <FiArrowRight />
                    </i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CalltoActionTwo;
