import React from "react";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import FooterOne from "../common/footer/FooterOne";
import Copyright from "../common/footer/Copyright";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import CalltoActionEight from "../elements/calltoaction/CalltoActionEight";
import TabOne from "../elements/tab/TabOne";
import CalltoActionTwo from "../elements/calltoaction/CalltoActionTwo";
import ServiceOneFacaParte from "../elements/service/ServiceOneFacaParte";
import CalltoActionThree from "../elements/calltoaction/CalltoActionThree";
import CalltoActionThreeOld from "../elements/calltoaction/CalltoActionThreeOld";

const callData = {
  title: "Seja um consultor!",
  subtitle:
    "Cada passo é uma oportunidade para transformar seu futuro e o de outros!",
  btnText: "Acessar Painel",
};

const FacaParte = () => {
  return (
    <>
      <SEO title="Faça Parte" />
      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          // HeaderSTyle="header-transparent"
        />

        {/* Start Slider Area  */}
        <div
          className="slider-area slider-style-1 height-850 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/etsparah/banner/faca-parte.png)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner pt--80 text-center">
                  <div>
                    <h3 className="rn-sub-badge">
                      <span className="theme-gradient">Junte-se à Família</span>
                    </h3>
                  </div>
                  <h1 className="title display-one">
                    Seja um microfranqueado ETS CORP
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Elements Area  */}
        <div className="rwt-tab-area rn-section-gap">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Nossos Benefícios"
                  title="Faça Parte da ETS CORP"
                  description=""
                />
              </div>
            </div>
            <TabOne />
          </div>
        </div>
        {/* End Elements Area  */}

        <Separator />

        {/* Start Service Area  */}
        {/* <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Consultor ETSCORP"
                  title="Seu Caminho para Empoderar<br/> Vidas Financeiramente"
                  description="Pronto para Transformar Vidas e Construir um Futuro Financeiro de Sucesso?<br/> 
                  <a href='' target='__brank'><b>Torne-se um Consultor ETSCORP Agora!</b></a>
                  "
                />
              </div>
            </div>
            <ServiceFour
              serviceStyle="service__style--1 icon-circle-style with-working-process"
              textAlign="text-center"
            />
          </div>
        </div> */}
        {/* End Service Area  */}

        <div className="rn-service-area rn-section-gap">
          <CalltoActionTwo />
        </div>

        <Separator />
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Passo a passo"
                  title="Microfranqueado digital da ETS CORP"
                  description="A oportunidade em suas mãos de se tornar um novo empreendedor."
                />
              </div>
            </div>
            <ServiceOneFacaParte
              serviceStyle="service__style--1 bg-color-blackest radius mt--25"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}
        <Separator />

        <div className="rwt-callto-action-area rn-section-gapBottom">
          <div className="wrapper">
            <CalltoActionThreeOld />
          </div>
        </div>

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};

export default FacaParte;
