import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <ul className="mainmenu">
      <li className="has-droupdown">
        <Link to="/">Home</Link>
      </li>

      <li>
        <Link to="/sobre-nos">Sobre nós</Link>
      </li>

      <li className="with-megamenu">
        <Link to="/ecossistema">Parcerias</Link>
      </li>

      <li className="has-droupdown">
        <Link to="/faca-parte">Faça Parte</Link>
      </li>

      <li className="has-droupdown">
        <Link to="/contato">Contato</Link>
      </li>
    </ul>
  );
};
export default Nav;
