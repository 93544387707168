import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
  {
    image: "/images/etsparah/banner/264x148-1.png",
    title: "Franquia Digital",
    description:
      "Oferecemos oportunidades exclusivas para empreendedores se tornarem parte de nossa rede de franquias digitais.",
    btnTitle: "Saiba mais",
  },
  {
    image: "/images/etsparah/banner/264x148-2.png",
    title: "Empresas Parceiras",
    description:
      "Colaboramos com empresas líderes no mercado de empreendedorismo digital para oferecer uma variedade de produtos e serviços.",
    btnTitle: "Saiba mais",
  },
  {
    image: "/images/etsparah/banner/264x148-3.png",
    title: "Plataforma Completa",
    description:
      "Nossa plataforma proporciona acesso a oportunidades de negócios em diversos setores.",
    btnTitle: "Saiba mais",
  },
  {
    image: "/images/etsparah/banner/264x148-4.png",
    title: "Seja um Microfranqueado",
    description:
      "Entre em contato agora mesmo e um de nossos especialistas realizará o seu cadastro.",
    btnTitle: "Saiba mais",
  },
];
const ServiceTwo = ({ textAlign, cardStyle }) => {
  return (
    <div className="row row--15 service-wrapper">
      {ServiceList.map((val, i) => (
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`card-box ${cardStyle} ${textAlign}`}>
              <div className="inner">
                <div className="image">
                  <Link to="#service">
                    <img src={`${val.image}`} alt="card Images" />
                  </Link>
                </div>
                <div className="content">
                  <h4 className="title mb--20">
                    <Link
                      to="#service"
                      dangerouslySetInnerHTML={{ __html: val.title }}
                    ></Link>
                  </h4>
                  <p
                    className="description b1 color-gray mb--0"
                    dangerouslySetInnerHTML={{ __html: val.description }}
                  ></p>
                  <Link
                    className="btn-default btn-small btn-border"
                    to="#service"
                  >
                    {val.btnTitle && val.btnTitle}
                  </Link>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceTwo;
